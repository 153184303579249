.buy-section {
  padding: 10rem 0;

  padding-top: 10rem;
  background: $black;
  p {
    margin-bottom: 5rem;
  }
  .form-inline {
    input {
      box-shadow: 0 0.1875rem 0.1875rem 0 rgba(0, 0, 0, 0.1) !important;
      padding: 1.25rem 2rem;
      height: auto;
      font-family: "Rye", cursive;
      font-size: 80%;
      text-transform: uppercase;
      letter-spacing: 0.15rem;
      border: 0;
    }
  }

  .custom-select-lg {
    height: calc(3.575rem + 2px);
  }
}

@import "variables.scss";
@import "mixins.scss";
@import "navbar.scss";
@import "masthead.scss";
@import "buttons.scss";
@import "signup.scss";

body {
  font-family: "Rye", cursive;
  letter-spacing: 0.0625em;
}

a {
  color: $primary;
  &:focus,
  &:hover {
    text-decoration: none;
    color: darken($primary, 20%);
  }
}

.bg-black {
  background-color: $black !important;
}

.bg-primary {
  background-color: $primary !important;
}

.text-primary {
  color: $primary !important;
}

footer {
  padding: 5rem 0;
}
